import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import Vant from 'vant'
import 'vant/lib/index.css'
import naviHeader from '@/components/PC/navigtor.vue'
import footerPage from '@/components/PC/footer.vue'
import srcollTop from '@/components/PC/scrollToTop.vue'
import backTop from '@/components/mobile/scrollToTop.vue'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from '@/lang'
// import '@/utils/rem.js'

Vue.config.productionTip = false
Vue.component('naviHeader', naviHeader)
Vue.component('footerPage', footerPage)
Vue.component('srcollTop', srcollTop)
Vue.component('backTop', backTop)

Vue.use(ElementUI)
Vue.use(Vant)
// 在浏览器使用开发者工具时，仅仅是更改了视口元数据和用户代理字符串，它并不会触发 window 的 resize 事件，需要单独去监听视口宽度
const mediaQuery = window.matchMedia('(max-width: 767px)')
mediaQuery.addListener(checkWindowWidth)

function setRem () {
  const isMobileDevice = window.matchMedia('(max-width: 767px)').matches
  let baseSize
  let scale

  // 检查窗口大小，如果小于700px，并且没有刷新过，则刷新并在localStorage设置标识
  if (window.innerWidth < 767) {
    if (!localStorage.getItem('hasReloaded')) {
      location.reload()
      localStorage.setItem('hasReloaded', 'true')
      return
    }
  } else if (window.innerWidth >= 767) {
    if (localStorage.getItem('hasReloaded')) {
      location.reload()
      localStorage.removeItem('hasReloaded')
      return
    }
  }

  if (!isMobileDevice) {
    baseSize = 50 // PC端基准字体大小
    scale = document.documentElement.clientWidth / 1440
    if (scale < 1) {
      scale = Math.round(scale)
    } else if (scale >= 2) {
      scale = 1
    } else {
      scale = Math.floor(scale)
    }
  } else {
    baseSize = 37.5 // 移动端
    scale = 1 // 固定为1
  }

  const size = baseSize * scale + 'px'
  document.documentElement.style.fontSize = size
}
// 这段代码将使你的页面只在首次从大屏幕与小屏幕转换时进行一次刷新，这里设置的是767px
function checkWindowWidth (list) {
  if (list.matches) {
    if (!localStorage.getItem('hasReloaded')) {
      localStorage.setItem('hasReloaded', 'true')
      location.reload()
    }
  } else if (!list.matches) {
    if (localStorage.getItem('hasReloaded')) {
      localStorage.removeItem('hasReloaded')
      location.reload()
    }
  }

  setRem()
}

// 初始执行一次
checkWindowWidth(mediaQuery)
// 初始设置一次
setRem()

// 然后监听 resize 事件，窗口尺寸改变时重新设置
window.addEventListener('resize', setRem)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
