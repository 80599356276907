// 英语
export default {
  route: {
    //   banner1
    Slogan1: 'Empowering digital lending with AI and algorithms',
    introduction: 'Leading technology company of digital lending, through artificial intelligence and algorithmic technology, provides corporate customers with digital intelligence, marketing, risk management and business consulting services, to help enterprises improve their financial services capabilities and efficiency',
    characteristica: 'Deep industry understanding and trend foresight',
    characteristicb: 'Leading intelligent technology',
    characteristicc: 'Senior localization operation experience',
    characteristicd: 'Professional and efficient team and service',
    // banner2
    // Slogan2: 'What we can provide to the industry',
    Slogan2: 'We Provide',
    characteristic1: 'Continuous technological innovation brings improved risk management',
    characteristic2: 'Senior experience of the local market to facilitate compliance',
    characteristic3: 'Professional business insights to increase revenue',
    characteristic4: 'Reduce cost, improve efficiency, and facilitate digital transformation',
    // ai数字投放
    aiTab: 'AI Ads',
    aiSlogan: 'Algorithmic Creativity for Business Growth',
    productFeatures: 'Product features',
    aiFeatures1: 'Customized growth solutions based on best practices',
    aiFeatures2: 'Innovative AIGC , efficient production of mass materials',
    aiFeatures3: 'Leading algorithms achieve quantitative and qualitative growth',
    aiFeatures4: 'Multi-dimensional data analysis brings deeper insights',
    estimate: 'Assess and plan',
    aiLeftListaOne: 'Diagnose and discover current problems',
    aiLeftListTwo: 'ASO through machine learning scoring to find the best creative for the client',
    aiLeftCenter: 'Experimental advertising',
    aiCenterList1: 'Create suitable creative materials, such as keywords, pictures, etc;',
    aiCenterList2: 'Adjust the bidding strategy, accumulate samples in the shortest time, and optimize the advertising algorithm through machine learning',
    Acquiring: 'Customer growth',
    aiRightList1: 'Generate more creatives through AI technology',
    aiRightList2: ' Increase customer acquisition and continuously optimize advertising recommendation algorithms and creatives',
    buttonStr: 'Check the details',
    // 数字身份识别
    numberTab: 'EKYC',
    numberSlogan: 'Experience the Best of Secure and Seamless EKYC with Advanced Technology',
    numberFeatures1: 'Leading OCR and liveness detection technology, high precision identification',
    numberFeatures2: 'Smooth and convenient user journey;',
    numberFeatures3: 'Artificial intelligence to prevent fraud and protect business from loss',
    numberLeftTitle1: 'Picture category',
    numberLeftIcon1: 'selfie',
    numberLeftIcon2: 'ID card',
    numberLeftTitle2: 'Text type',
    numberLeftIcon3: 'device ID',
    numberLeftIcon4: 'phone number',
    numberLeftIcon5: 'bank card',
    numberLeftIcon6: 'name',
    numberLeftIcon7: 'ID card number',
    numberCenterStr1: 'Acquisition',
    numberCenterStr2: 'Verification',
    numberRightIcon1: 'Face comparison',
    numberRightIcon2: 'face search',
    numberRightIcon3: 'document comparison',
    numberRightIcon4: 'bank card verification',
    numberRightIcon5: 'device ID verification',
    numberRightIcon6: 'phone number verification',
    // 云盾信用分
    creditTab: 'Cloudun Score',
    creditSlogan: 'Boost Your Business Performance with Reliable Credit Insights',
    creditFeatures1: 'Rich practical experience',
    creditFeatures2: 'Cutting-edge algorithmic capabilities',
    creditFeatures3: 'Massive models, diverse scores',
    creditFeatures4: 'Stable risk performance',
    creditLeftStr1: 'Phone',
    creditLeftStr2: 'name',
    creditLeftStr3: 'ID card number',
    creditLeftStr4: 'user behavior',
    creditRightStr1: 'blacklist query',
    creditRightStr2: 'customized features',
    creditRightStr3: 'pre-screening',
    creditRightStr4: 'directional decision',
    creditRightStr5: 'comprehensive decision',
    // 云盾咨询
    consultTab: 'Business Copilot',
    consultSlogan: 'Partner with Us for Profitable Growth',
    consultFeatures1: 'Deep understanding of local compliance policies',
    consultFeatures2: 'Rich experience in localization operation',
    consultFeatures3: 'Professional product design and operational growth capabilities',
    consultFeatures4: 'Flexible "componentized" customized service model',
    consultFeatures5: 'Data-driven management, data-driven growth',
    consultStr1: 'Business consulting',
    consultStr2: 'deployment implementation',
    consultStr3: 'risk management',
    consultStr4: 'operation guidance',
    consultStr5: 'business analysis',
    consultStr6: 'value-added services',
    // 行业应用区
    all: 'Total',
    advertisement: 'Advertising',
    digitalIntelligenceUserExperience: 'Digitalized user experience',
    riskManagement: 'Risk Management',
    compliantOperationsAndBusinessGrowth: 'Compliance and business growth',
    // 行业应用数据简介
    industryApplications1: 'Algorithm optimizes ASO to improve leads quantity and quality',
    industryApplications2: 'EKYC, remote identity authentication and document recognition, complete transactions online',
    industryApplications3: 'Face search blacklist, massive database, identify "risk people"',
    industryApplications4: 'Face comparison, accurate identification of the "same person", to prevent fraud risk',
    industryApplications5: 'Multi-dimensional mass data, all-round risk prevention',
    industryApplications6: 'Multi-debt characteristics identification, user life cycle tracking and control, to achieve risk prediction',
    industryApplications7: 'Customized feature output to meet different types of risk management needs',
    industryApplications8: 'Credit score multi-scenario adaptation, maximize benefits while strictly prevent default risk',
    industryApplications9: 'Deep understanding of the industry, localized business solutions',
    industryApplications10: 'Compliant, smooth, high-conversion product design',
    industryApplications11: 'Data-driven business operation tracking, periodic business analysis review',
    industryApplications12: 'Global data collection, insight into the status quo, transaction early warning, achievement growth',
    // 行业应用标签
    tab1: '#AIGC',
    tab2: '#Marketing',
    tab3: '#OCR',
    tab4: '#Liveness',
    tab5: '#EKYC',
    tab6: '#FaceSearch',
    tab7: '#FaceComparison',
    tab8: '#Blacklist',
    tab9: '#FeatureLab',
    tab10: '#Multi-debtIdentification',
    tab11: '#CustomizedFeature',
    tab12: '#CreditScore',
    tab13: '#RiskDecisions',
    tab14: '#BusinessConsulting',
    tab15: '#DigitalLoanSolutions',
    tab16: '#Localization',
    tab17: '#BusinessAnalysis',
    tab18: '#OperationAnalysis',
    tab19: '#VisualReport',
    tab20: '#Data- drivenOperation',
    // navigtor导航栏
    home: 'Home',
    Partners: 'Cooperation Partners',
    productMatrix: 'Product Matrix',
    BestPractices: 'Best Practices',
    Aboutus: 'About Us',
    News: 'News',
    freeTrial: 'Free trial',
    // Chinese: 'Chinese',
    // English: 'English',
    // Mexico: 'Mexico',
    // Indonesia: 'Indonesia',
    download: 'Scan code to download trial',
    // 底部选项
    solve: 'Solution pattern',
    industry: 'Industry application',
    Rating: 'Cloudun Credit Score',
    CompanyProfile: 'Company Profile',
    development: 'Development path',
    cellWe: 'Contact us',
    business: 'Business Consulting:',
    customer: 'Customer service:',
    // 最佳实践
    headline: 'A leading technology partner trusted around the world',
    subtitle1: 'Professional ',
    subtitle2: 'Solutions, ',
    subtitle3: ' Efficient ',
    subtitle4: 'Service',
    mode1: 'Cloud call',
    modeContent1: 'Access services through API, flexible and efficient.Products:EKYC、blacklist、features、 generated scores.',
    mode2: 'Privatization Deployment',
    modeContent2: 'Customization solutions, operation tracking, safety control, more efficient profit growth. Products: Business copilot.',
    mode3: 'Proxy Hosting',
    modeContent3: 'Products:AI Ads;Fully responsible for accounts opening and management, creation of advertising materials, implementation and optimization of strategies',
    // 关于我们
    CompanyProfile1: 'Founded in Shanghai in 2019, focusing on “Commercial Application of Decision Science”, Cloudun AI integrates technology, customer insight, financial scenarios, and combination of products&operations, aiming to empower traditional financial institutions in the era of big data.',
    CompanyProfile2: 'The team has rich working experience from well-known financial companies of China in the early stage. We have profound professional insights into financial technologies and products, professional understanding and analysis of customer needs, solid theoretical basis and rich practical experience in the field of risk control management.We are committed to providing enterprises with comprehensive, whole-process "decision-making" capabilities in the Big Data era, whether with experiences or not. By enabling analytics team members of all skill levels to handle all tasks in the analytics lifecycle in a simple, powerful and automated way. Cloudun AI already serves clients in Indonesia, Thailand, Nigeria and Mexico.',
    new2018StrHeader: 'Shanghai Fintek ',
    new2018StrCenter: 'was founded, mainly engaged in loan SaaS developing;',
    new2019Str1Header: 'Cloudun Cayman & Singapore ',
    new2019Str1Center: 'was incorporated in May 2019;',
    new2019Str2: 'The group started exploring fintech AI and technology services in Indonesia.',
    new2020StrHeader: 'Cloudun Indonesia ',
    new2020StrCenter: 'was incorporated, started to provide EKYC、Credit insights、Digital lending consulting services in Indonesia;',
    new2020Str1Header: 'On 2020, Cloudun provided services for 37 enterprise clients, with API call volume of nearly ',
    new2020Str1Center: '40 million times.',
    new2021Str1Header: 'Cloudun HK ',
    new2021Str1Center: 'was established, started business in Mexico, expanding product lines: Digital marketing services;',
    new2021Str2Header: 'By the end of  2021, Cloudun provided services for 67 enterprise clients, with API call volume of over ',
    new2021Str2Center: '90 million times.',
    new2022Str1Header: 'Cloudun Group ',
    new2022Str1Center: 'is continuously optimizing products in the Mexico and Indonesian markets, constantly increasing enterprise clients, and maintaining sustainable business growth. In 2023, API calls have reached to ',
    new2022Str1footer: '270 million times that year;',
    new2022Str2Header1: 'By Dec 2023, Cloudun has provided services for ',
    new2022Str2Center1: '90 enterprise ',
    new2022Str2Header2: 'clients with a financial transaction volume of ',
    new2022Str2Center2: '3 billion ',
    new2022Str2Header3: 'dollars and has connected nearly ',
    new2022Str2Center3: '50 million end users;',
    new2022Str3: 'Completed Series A Funding.',
    new2024Str1Center1: 'On the basis of maintaining stable growth in the existing market,',
    new2024Str1Header: 'Cloudun will ',
    new2024Str1Center2: 'explore horizontally into other regions such as the Philippines and vertically advance the development of intelligent operation systems such as smart voice systems;',
    new2024Str2: 'Actively cultivating investments along the industrial chain, seeking opportunities to leverage synergies in the value chain.',
    // 产品二级页
    // ai数字广告
    productAITitle: 'AI Ads',
    productAITitle1: 'AI',
    productAITitle2: 'Ads',
    productAISubTitle: 'Algorithmic Creativity for Business Growth',
    productAIMode1: 'Leverage AI capabilities to create and derive advertising materials',
    productAIMode2: 'Continuously optimizing ad performance through algorithms',
    productAIMode3: 'Empowers enterprise clients to acquire qualified consumers at scale with most cost-effective solution',
    productAIHighlights1: 'No clue to bid',
    productAIHighlightScorrect1: 'Strategy optimization by machine learning algorithm',
    productAIHighlights2: 'Low efficient material production, poor effectiont',
    productAIHighlightScorrect2: 'Algorithm to find the best campaign, AIGC to achieve material precipitation and derivation, produce massive materials in a short time',
    recommendProduct: 'These companies are using',
    // 数字身份识别
    productNumberTitle1: 'EKYC',
    productNumberTitle2: '',
    productNumberSubTitle: 'Experience advanced, secure and smooth EKYC service',
    productNumberMode1: 'Efficient collection of identity information, accurate identification of “real person”, “same person”, “person credit risky”',
    productNumberMode2: 'Convenient and accurate',
    productNumberHighlightScorrect1: 'Submit the responsible paper form or sign in person to complete the transaction',
    productNumberHighlightScorrect2: 'Requires complex manual authentication process',
    productNumberHighlightScorrect3: 'First-class digital login experience through ID identification and biometric authentication',
    productNumberHighlightScorrect4: 'Multi-dimensional information collection, efficient identification of fraud risks.',
    // 信用评分
    productCreditTitle: 'Cloudun Credit Score',
    productCreditMode1: 'Provides highly accurate credit insights using massive data to analyze encrypted information at scale via its proprietary machine learning credit scoring model，Empowers enterprise lenders to make more informed decisions on individual consumers credit worthiness.',
    productCreditMode2: 'Includes Feature Lab and Generated Score.',
    productCreditHighlights1: 'How to identify fraud more comprehensively',
    productCreditHighlightScorrect1: 'Multidimensional massive data blacklist query',
    productCreditHighlights2: 'How to upgrade the risk control strategy &gt',
    productCreditHighlightScorrect2: 'Customized features, directional output',
    productCreditHighlights3: 'The effect of risk control has not been improved',
    productCreditHighlightScorrect3: 'Integrated decision-making, reliable credit insight',
    productCreditHighlights4: 'Rejected some users by mistake, resulting in loss',
    productCreditHighlightScorrect4: 'Targeted decision making, user retention and profit recovery',
    // 云盾咨询
    produCtonsultTitle: 'Business Copilot',
    produCtonsultTitle1: 'Copilot',
    produCtonsultTitle2: 'Business',
    produCtonsultSubTitle: 'Partner with Us for Profitable Growth',
    produCtonsultMode1: 'From consultation, business model establishment, customized development and deployment, to operation guidance and operation analysis in the early stage of business, and finally to complete transfer of operation strategy and the code, to achieve comprehensive business solutions.',
    produCtonsultHighlights1: 'No clue to localize the new market',
    produCtonsultHighlightScorrect1: 'Deep understanding of the industry, localized business solutions',
    produCtonsultHighlights2: 'Low conversion of new customers and low retention of old customers',
    produCtonsultHighlightScorrect2: 'Compliant, smooth, high-conversion client product design',
    produCtonsultHighlights3: 'Sustained losses or low profits',
    produCtonsultHighlightScorrect3: 'Comprehensive commercial operation solution',
    // 新增词汇
    statusTitle: 'Industry status',
    advantageTitle: 'Solution advantages',
    modeTitle: 'Mode introduction',
    headline1: 'A leading technology partner',
    headline2: 'trusted around the world',
    organizationStructure: 'Organization Chart',
    certificate: 'Certifications & Licenses',
    progress: 'In Progress',
    registered: 'Registered',
    address: 'Detailed Address',
    location: 'Location',
    latest: 'Latest Releases',
    next: 'Next'
  },
  // 云盾信用分
  credit: {
    productCreditTitle1: 'Credit Score',
    productCreditTitle2: 'Cloudun',
    productCreditSubTitle: 'Dependable risk intelligence leading business robust expansion',
    productCreditIntroduce: 'Our comprehensive consideration of risk management and economic models, will help your business control risks, reduce costs, and achieve sustainable operations and maximized profits.',
    productCreditValueTitle: 'Value we deliver to digital finance industry',
    productCreditValueItem1Title: 'Upgrade risk management',
    productCreditValueItem1Text1: 'With Solid risk management theory, successful practical experience, frontier technology and leading algorithm, combined with flexible &user friendly product design, we provide solutions for loan business, such as pre-audit,customer life cycle risk management, credit tracking, etc.',
    productCreditValueItem1Text2: 'We help the industry to upgrade their risk control capabilities digitally and intelligently, to better cop with potential risk.',
    productCreditValueItem2Title: 'Reduce cost and increase revenue',
    productCreditValueItem2Text: 'Track and manage the whole life cycle of customers, dynamically update the risk rating, achieve fine cost management and the establishment of the best revenue model in terms of preventing unnecessary repeated applications, timely activation and awakening to improve retention, and automatic audit,etc, so as toimprove profits.',
    productCreditValueItem3Title: 'Facilitate digital transformation',
    productCreditValueItem3Text: 'With Leading technology and user experience , we facilitate the digital transformation of the financial services industry, enhance the competitiveness of the industry, while constantly implementing regulatory requirements.',
    stepTitle: 'How our product works',
    flowPath1: 'User submit info',
    flowPath2: 'Data cleaning',
    flowPath3: 'Feature Lab',
    flowPath4: 'Model',
    flowPath6: 'Data vendor',
    preAssessment1: 'Application Pre-',
    preAssessment2: 'assessment',
    preAssessmentText: 'Pre-assessment to avoid repeating application of unqualified users, which may cause bad user experience and waste of cost.',
    preAudit1: 'Pre-',
    preAudit2: 'audit',
    preAuditText: "Issue preliminary audit results based on user's three indentity factors",
    decisionMaking1: 'Comprehensive decision-',
    decisionMaking2: 'making',
    decisionMakingText: 'Integrating multi-dimensional information such as user credit,behavioral data,multi-debts situation and historical credit record,the risk management model is established and risk control decisions are provided'
  }
}
