// 西语
export default {
  route: {
    //   banner1
    Slogan1: 'Empoderar los préstamos digitales con IA y algoritmos',
    introduction: 'Empresa líder en tecnología de préstamos digitales, a través de inteligencia artificial y tecnología algorítmica, brinda a los clientes corporativos servicios de inteligencia digital, marketing, gestión de riesgos y consultoría comercial para ayudar a las empresas a mejorar sus capacidades y eficiencia de servicios financieros.',
    characteristica: 'Comprensión profunda de la industria y previsión de tendencias',
    characteristicb: 'Tecnología inteligente líder',
    characteristicc: 'Experiencia en operaciones de localización senior',
    characteristicd: 'Equipo y servicio profesional y eficiente',
    // banner2
    Slogan2: 'Qué podemos ofrecer a la industria',
    characteristic1: 'La innovación tecnológica continua trae una mejor gestión de riesgos',
    characteristic2: 'Experiencia senior del mercado local para facilitar el cumplimiento',
    characteristic3: 'Conocimientos comerciales profesionales para aumentar los ingresos',
    characteristic4: 'Reducir costos, mejorar la eficiencia y facilitar la transformación digital',
    // ai数字投放
    aiTab: 'Anuncios de IA',
    aiSlogan: 'Creatividad algorítmica para el crecimiento empresarial',
    productFeatures: 'Características del producto',
    aiFeatures1: 'Soluciones de crecimiento personalizadas basadas en las mejores prácticas',
    aiFeatures2: 'AIGC innovador, producción eficiente de materiales en masa',
    aiFeatures3: 'Los algoritmos líderes logran un crecimiento cuantitativo y cualitativo',
    aiFeatures4: 'El análisis de datos multidimensional aporta conocimientos más profundos',
    estimate: 'Evaluar y planificar',
    aiLeftListaOne: 'Diagnosticar y descubrir problemas actuales',
    aiLeftListTwo: 'ASO a través de puntuación de aprendizaje automático para encontrar la mejor creatividad para el cliente',
    aiLeftCenter: 'Publicidad experimental',
    aiCenterList1: 'Crear materiales creativos adecuados, como palabras clave, imágenes, etc;',
    aiCenterList2: 'Ajuste la estrategia de licitación, acumule muestras en el menor tiempo posible y optimice el algoritmo publicitario a través del aprendizaje automático.',
    Acquiring: 'Crecimiento de clientes',
    aiRightList1: 'Generar más creatividades a través de la tecnología de IA;',
    aiRightList2: 'Aumente la adquisición de clientes y optimice continuamente los algoritmos y creatividades de recomendación de publicidad.',
    buttonStr: 'Revisar los detalles',
    // 数字身份识别
    numberTab: 'EKYC',
    numberSlogan: 'Experimente lo mejor de EKYC seguro y sin inconvenientes con tecnología avanzada',
    numberFeatures1: 'Tecnología líder de OCR y detección de vida, identificación de alta precisión',
    numberFeatures2: 'Viaje de usuario suave y conveniente',
    numberFeatures3: 'Inteligencia artificial para prevenir el fraude y proteger el negocio de pérdidas',
    numberLeftTitle1: 'Categoría de imagen: ',
    numberLeftIcon1: 'selfie',
    numberLeftIcon2: 'tarjeta de identificación',
    numberLeftTitle2: 'Tipo de texto: ',
    numberLeftIcon3: 'ID del dispositivo',
    numberLeftIcon4: 'número de teléfono',
    numberLeftIcon5: 'tarjeta bancaria',
    numberLeftIcon6: 'nombre',
    numberLeftIcon7: 'número de tarjeta de identificación',
    numberCenterStr1: 'Adquisición',
    numberCenterStr2: 'Verificación',
    numberRightIcon1: 'Comparación de rostros',
    numberRightIcon2: 'búsqueda de rostros',
    numberRightIcon3: 'comparación de documentos',
    numberRightIcon4: 'verificación de tarjeta bancaria',
    numberRightIcon5: 'verificación de ID de dispositivo',
    numberRightIcon6: 'verificación de número de teléfono',
    // 云盾信用分
    creditTab: 'Cloudun Score',
    creditSlogan: 'Aumente el rendimiento de su negocio con información crediticia confiable',
    creditFeatures1: 'Rica experiencia práctica',
    creditFeatures2: 'Capacidades algorítmicas de vanguardia',
    creditFeatures3: 'Modelos masivos, puntuaciones diversas',
    creditFeatures4: 'Desempeño de riesgo estable',
    creditLeftStr1: 'Teléfono',
    creditLeftStr2: 'nombre',
    creditLeftStr3: 'número de tarjeta de identificació',
    creditLeftStr4: 'comportamiento del usuario',
    creditRightStr1: 'consulta de lista negra',
    creditRightStr2: 'características personalizadas',
    creditRightStr3: 'preselección',
    creditRightStr4: 'decisión direccional',
    creditRightStr5: 'decisión integral',
    // 云盾咨询
    consultTab: 'Copiloto de Negocios',
    consultSlogan: 'Asóciese con nosotros para un crecimiento rentable',
    consultFeatures1: 'Profundo conocimiento de las políticas locales de cumplimiento',
    consultFeatures2: 'Rica experiencia en operaciones de localización',
    consultFeatures3: 'Diseño profesional de productos y capacidades de crecimiento operativo',
    consultFeatures4: 'Modelo de servicio personalizado "componentizado" flexible',
    consultFeatures5: 'Gestión basada en datos, crecimiento basado en dato',
    consultStr1: 'Consultoría empresarial',
    consultStr2: 'implementación',
    consultStr3: 'gestión de riesgos',
    consultStr4: 'orientación operativa',
    consultStr5: 'análisis empresarial',
    consultStr6: 'servicios de valor añadido',
    // 行业应用区
    all: 'Total',
    advertisement: 'Publicidad',
    digitalIntelligenceUserExperience: 'Experiencia de usuario digitalizada',
    riskManagement: 'Gestión de riesgos',
    compliantOperationsAndBusinessGrowth: 'Cumplimiento y crecimiento empresarial',
    // 行业应用数据简介
    industryApplications1: 'El algoritmo optimiza ASO para mejorar la cantidad y la calidad de los clientes potenciales',
    industryApplications2: 'EKYC, autenticación remota de identidad y reconocimiento de documentos, transacciones completas en línea',
    industryApplications3: 'Lista negra de búsqueda de rostros, base de datos masiva, identificación de "personas de riesgo"',
    industryApplications4: 'Comparación de rostros, identificación precisa de la "misma persona", para evitar el riesgo de fraude',
    industryApplications5: 'Datos masivos multidimensionales, prevención integral de riesgos',
    industryApplications6: 'Identificación de características de múltiples deudas, seguimiento y control del ciclo de vida del usuario, para lograr la predicción del riesgo',
    industryApplications7: 'Salida de características personalizadas para satisfacer diferentes tipos de necesidades de gestión de riesgos',
    industryApplications8: 'Adaptación de múltiples escenarios de puntuación de crédito, maximiza los beneficios mientras previene estrictamente el riesgo de incumplimiento',
    industryApplications9: 'Conocimiento profundo de la industria, soluciones comerciales localizadas',
    industryApplications10: 'Diseño de producto compatible, fluido y de alta conversión',
    industryApplications11: 'Seguimiento de operaciones comerciales basadas en datos, revisión periódica de análisis comercial',
    industryApplications12: 'Recopilación de datos globales, información sobre el estado quo, alerta temprana de transacciones, crecimiento de logros',
    // 行业应用标签
    tab1: '#AIGC',
    tab2: '#Marketing',
    tab3: '#OCR',
    tab4: '#Viveness',
    tab5: '#EKYC',
    tab6: '#Búsqueda de rostros',
    tab7: '#Comparación de rostros',
    tab8: '#Lista negra',
    tab9: '#FeatureLab',
    tab10: '#Identificación de múltiples deudas',
    tab11: '#Característica personalizada',
    tab12: '#Puntaje crediticio',
    tab13: '#Decisiones de riesgo',
    tab14: '#BusinessConsulting',
    tab15: '#DigitalLoanSolutions',
    tab16: '#Localización',
    tab17: '#Análisis de negocio',
    tab18: '#Análisis de operación',
    tab19: '#VisualReport',
    tab20: '#Operación basada en datos',
    // navigtor导航栏
    home: 'Página de inicio',
    Partners: 'Socio',
    productMatrix: 'Matriz de productos',
    BestPractices: 'Mejores prácticas',
    Aboutus: 'Sobre nosotros',
    News: 'news',
    freeTrial: 'Prueba gratis',
    // Chinese: 'Chino',
    // English: 'Inglés',
    // Mexico: 'México',
    // Indonesia: 'Indonesia',
    download: 'Escanea el código para descargar la versión de prueba',
    // 底部选项
    solve: 'Patrón de solución',
    industry: 'Aplicación de la industria',
    Rating: 'Cloudun Credit Score',
    CompanyProfile: 'Perfil de la empresa',
    development: 'El curso de la empresa',
    cellWe: 'Contáctenos',
    business: 'Consulta de negocios:',
    // 最佳实践
    headline: 'Un socio tecnológico líder de confianza en todo el mundo',
    subtitle1: 'Soluciones ',
    subtitle2: 'Profesionales, ',
    subtitle3: 'Servicio ',
    subtitle4: 'Eficiente',
    mode1: 'Llamada en la nube',
    modeContent1: 'Accede a servicios a través de API, flexible y eficiente.Productos: EKYC, lista negra, características, puntajes generados.',
    mode2: 'Despliegue de la privatización',
    modeContent2: 'Soluciones de personalización, seguimiento de operaciones, control de seguridad, crecimiento de ganancias más eficiente. Productos: Copiloto empresarial.',
    mode3: 'Alojamiento proxy',
    modeContent3: 'Productos:Anuncios de IA;Totalmente responsable de la apertura y gestión de cuentas, creación de materiales publicitarios, implementación y optimización de estrategias.',
    // 关于我们
    CompanyProfile1: 'Fundada en Shanghái en 2019, centrándose en la "Aplicación comercial de la ciencia de la decisión", Cloudun AI integra tecnología, conocimiento del cliente, escenarios financieros y combinación de productos y operaciones, con el objetivo de empoderar a las instituciones financieras tradicionales en la era de los grandes datos.',
    CompanyProfile2: 'El equipo tiene una rica experiencia laboral de reconocidas compañías financieras de China en la etapa inicial. Contamos con un profundo conocimiento profesional de las tecnologías y los productos financieros, una comprensión y un análisis profesionales de las necesidades de los clientes, una base teórica sólida y una rica experiencia práctica en el campo de la gestión del control de riesgos. Estamos comprometidos a proporcionar a las empresas capacidades integrales de "toma de decisiones" de todo el proceso en la era de Big Data, ya sea con experiencia o sin ella. Al permitir que los miembros del equipo de análisis de todos los niveles de habilidad manejen todas las tareas en el ciclo de vida del análisis de una manera simple, potente y automatizada. Cloudun AI ya atiende a clientes en Indonesia, Tailandia, Nigeria y México.',
    new2018StrHeader: 'Shanghai Fintek fue fundada, ',
    new2018StrCenter: 'dedicada principalmente a préstamo Desarrollo de SaaS.',
    new2019Str1Header: 'Cloudun Caimán y Singapur ',
    new2019Str1Center: 'se constituyó en mayo de 2019;',
    new2019Str2: 'El grupo comenzó a explorar los servicios de tecnología e inteligencia artificial en Indonesia.',
    new2020StrHeader: 'Cloudun Indonesia ',
    new2020StrCenter: 'se estableció y lanzó oficialmente los servicios EKYC, Crédito Score, Publicación Digital, Consulta Negocial & Operación en Indonesia;',
    new2020Str1Header: 'Hasta 2020, Cloudun ha prestado servicio a 37 clientes empresariales, con un volumen acumulado del uso de producto de ',
    new2020Str1Center: '40 millones veces durante el año.',
    new2021Str1Header: 'Se estableció Cloudun HK, ',
    new2021Str1Center: 'inició negocios en México, ampliando líneas de productos: Servicios de marketing digital;',
    new2021Str2Header: 'A finales de 2021, Cloudun prestaba servicio a 67 clientes empresariales, con un número acumulado del uso de producto superior a ',
    new2021Str2Center: '90 millones veces en ese año.',
    new2022Str1Header: 'Cloudun Group ',
    new2022Str1Center: 'optimiza continuamente los productos en los mercados de México e Indonesia, aumenta constantemente los clientes empresariales y mantiene un crecimiento empresarial sostenible. En 2023, el uso del producto ha alcanzado los ',
    new2022Str1footer: '270 millones de veces ese año;',
    new2022Str2Header1: 'En diciembre de 2023, Cloudun había prestado servicios a ',
    new2022Str2Center1: '90 clientes ',
    new2022Str2Header2: 'empresariales con un volumen de transacciones financieras de ',
    new2022Str2Center2: '3.000 millones',
    new2022Str2Header3: 'de dólares y había conectado a casi ',
    new2022Str2Center3: '50 millones de usuarios finales;',
    new2022Str3: 'Finalización de la financiamiento de la serie A.',
    new2024Str1Center1: 'Sobre la base de mantener un crecimiento estable en el mercado actual, ',
    new2024Str1Header: 'Cloudun explorará ',
    new2024Str1Center2: 'horizontalmente otras regiones, como Filipinas, y avanzará verticalmente en el desarrollo de sistemas operativos inteligentes, como los sistemas de voz inteligentes;',
    new2024Str2: 'Invertir activamente en la cadena industrial y buscar oportunidades para aprovechar las sinergias a lo largo de la cadena de valor.',
    // 产品二级页
    // ai数字广告
    productAITitle: 'Anuncios de IA',
    productAITitle1: 'IA',
    productAITitle2: 'Anuncios de',
    productAISubTitle: 'Creatividad algorítmica para el crecimiento empresarial',
    productAIMode1: 'Aprovechar las capacidades de IA para crear y derivar materiales publicitarios',
    productAIMode2: 'Optimizando continuamente el rendimiento de los anuncios a través de algoritmos',
    productAIMode3: 'permite a los clientes empresariales adquirir consumidores calificados a escala con la solución más rentable.',
    productAIHighlights1: 'Sin pistas para ofertar',
    productAIHighlightScorrect1: 'Optimización de la estrategia mediante un algoritmo de aprendizaje automático',
    productAIHighlights2: 'Producción de materiales de baja eficiencia, pobre efecto',
    productAIHighlightScorrect2: 'Algoritmo para encontrar la mejor campaña, AIGC para lograr la precipitación y derivación de materiales, producir materiales masivos en poco tiempo',
    recommendProduct: 'Estas empresas están usando',
    // 数字身份识别
    productNumberTitle1: 'EKYC',
    productNumberTitle2: '',
    productNumberSubTitle: 'Experimente un servicio EKYC avanzado, seguro y fluido',
    productNumberMode1: 'Recopilación eficiente de información de identidad, identificación precisa de "persona real", "misma persona", "persona de riesgo crediticio"',
    productNumberMode2: 'Conveniente y precisa',
    productNumberHighlightScorrect1: 'Envíe el formulario en papel responsable o firme en persona para completar la transacción',
    productNumberHighlightScorrect2: 'Requiere un complejo proceso de autenticación manual',
    productNumberHighlightScorrect3: 'Experiencia de inicio de sesión digital de primera clase a través de identificación de identificación y autenticación biométrica',
    productNumberHighlightScorrect4: 'Recopilación de información multidimensional, identificación eficiente de riesgos de fraude.',
    // 信用评分
    productCreditTitle: 'Cloudun Credit Score',
    productCreditMode1: 'Brinda información crediticia de alta precisión utilizando datos masivos para analizar información cifrada a escala a través de su modelo patentado de calificación crediticia de aprendizaje automático, permite a los prestamistas empresariales tomar decisiones más informadas sobre la solvencia crediticia de los consumidores individuales.',
    productCreditMode2: 'Incluye Feature Lab y Score generado.',
    productCreditHighlights1: '1) Cómo identificar el fraude de manera más integral',
    productCreditHighlightScorrect1: 'Consulta de lista negra de datos masivos multidimensionales',
    productCreditHighlights2: 'Cómo actualizar la estrategia de control de riesgos &gt',
    productCreditHighlightScorrect2: 'Características personalizadas, salida direccional',
    productCreditHighlights3: 'No se ha mejorado el efecto del control de riesgos',
    productCreditHighlightScorrect3: 'Toma de decisiones integrada, conocimiento crediticio confiable',
    productCreditHighlights4: 'Rechazó a algunos usuarios por error, lo que resultó en pérdidas',
    productCreditHighlightScorrect4: 'Toma de decisiones específicas, retención de usuarios y recuperación de ganancias',
    // 云盾咨询
    produCtonsultTitle: 'Copiloto de Negocios',
    produCtonsultTitle1: ' de Negocios',
    produCtonsultTitle2: 'Copiloto',
    produCtonsultSubTitle: 'Asóciese con nosotros para un crecimiento rentable',
    produCtonsultMode1: 'Desde la consulta, el establecimiento del modelo comercial, el desarrollo y la implementación personalizados, hasta la orientación y el análisis de operaciones en la etapa inicial del negocio y, finalmente, para transferencia completa de la estrategia de operación y el código, para lograr soluciones integrales de negocios.',
    produCtonsultHighlights1: 'Ninguna pista para localizar el nuevo mercado',
    produCtonsultHighlightScorrect1: 'Conocimiento profundo de la industria, soluciones comerciales localizadas',
    produCtonsultHighlights2: 'Baja conversión de nuevos clientes y baja retención de clientes antiguos',
    produCtonsultHighlightScorrect2: 'Diseño de producto de cliente compatible, fluido y de alta conversión',
    produCtonsultHighlights3: 'Pérdidas sostenidas o bajas ganancias',
    produCtonsultHighlightScorrect3: 'Solución integral de operación comercial',
    // 新增词汇
    statusTitle: 'Estado de la industria',
    advantageTitle: 'Ventajas de la solución',
    modeTitle: 'Introducción al modo',
    headline1: 'Un socio tecnológico líder de',
    headline2: 'confianza en todo el mundo',
    organizationStructure: '组织架构',
    certificate: '认证和许可证',
    progress: '进行中',
    registered: '已申请',
    address: '公司地址',
    location: '地点',
    latest: '最新发布',
    next: '下一篇'
  },
  credit: {
    productCreditTitle1: ' Credit Score',
    productCreditTitle2: 'Cloudun',
    productCreditSubTitle: 'Aumente el rendimiento de su negocio con información crediticia confiable'
  }
}
