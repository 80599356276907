<template>
    <div>
        <img class="tip" v-if="shouldShowScrollToTop"  @click="scrollTop" src="@/assets/PC/回到顶部.png" alt="">
    </div>
</template>

<script>
export default {
  name: 'scrollTop',
  data () {
    return {
      shouldShowScrollToTop: false

    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll) // 监听窗口滚动事件
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll) // 移除滚动事件监听器
  },
  methods: {
    handleScroll () {
      const threshold = window.innerHeight // 阈值为视口高度
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 获取当前页面已滚动的距离，应兼容不同浏览器情况
      if (scrollTop >= threshold) {
        this.shouldShowScrollToTop = true // 当超过阈值时显示按钮
      } else {
        this.shouldShowScrollToTop = false // 否则隐藏按钮
      }
    },
    scrollTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动
      })
    }

  }
}
</script>

<style scoped lang="less">
// 回到顶部
.tip {
    position: fixed;
    bottom: 4rem;
    right: 0;
    color: white;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    z-index: 999;
    width: 1.12rem;
    height: 0.6rem;
}

</style>
